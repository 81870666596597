.App {
  text-align: left;
  height: 90vh;
  background-color: #475;
  margin-left: 100;
  display: block;
  overflow: scroll;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: sticky;
  top: 0px;
  margin: 0 0 0 0;
}

.App-link {
  color: #61dafb;
}

button {
  border-radius: 4px;
  background-color: darkgreen;
  border: red;
  border-width: thick;
  color: #ffffff;
  text-align: center;
  transition: all 0.5s;
  cursor: pointer;
  position: relative;
  margin-right: 4px;
}

.console {
  position: fixed;
  height: 10vh;
  bottom: 0;
  left: 0;
}

.zdbSel {
  width: 100px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 40;
}

.mask {
  width: 50px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.qPlace {
  width: 100px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.qSel {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tiny {
  width: 20px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

textarea {
  width: 1090px;
  height: 9vh;
}

.wrapper {
  flex-direction: row;
  position: sticky;
  margin-left: 0;
  top: 0px;
  margin: 0 0 0 0;
  z-index: 100;
}

.serviceCall {
  text-align: center;
  background-color: #fff;
  border-radius: 5px;
  animation: blinkingBackground 2s infinite;
  font-weight: bold;
  font-size: 2rem;
}
@keyframes blinkingBackground {
  0% {
    background-color: #10c018;
  }
  25% {
    background-color: #1056c0;
  }
  50% {
    background-color: #ef0a1a;
  }
  75% {
    background-color: #254878;
  }
  100% {
    background-color: #04a1d5;
  }
}

myTable {
  margin: 2rem;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: scroll;
}
Table thead {
  position: sticky;
  top: 0px;
  margin: 0 0 0 0;
}
fld {
  display: "flex";
  flexdirection: "row";
  alignitems: "center";
}

th {
  border: "solid 2px red";
  background: "aliceblue";
  color: "black";
  fontweight: "bold";
}
tr {
  background: "lightyellow";
}

td {
  padding: 0.5rem 1rem 0.5rem 1rem;
  background: "lightyellow";
  white-space: nowrap;
  overflow: auto;
}
.resizer {
  display: inline-block;
  background: lightblue;
  width: 2px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  /* prevent scroll on touch devices 
  touch-action: none;*/
}

.isResizing {
  background: red;
}
